import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor() { }
}

export const API_URL = 'https://dins.versatils.org';// En mi local http://localhost:8083/public https://dins.versatils.org
// export const API_URL = 'http://api.dinsvalencia.com';
