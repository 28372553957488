import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { AlertService } from '../services/alert.service';
import { first } from 'rxjs/operators';

import swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  year: any;
  formLogin: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private authenticationService: AuthenticationService,
              private alertService: AlertService) {

              if (this.authenticationService.currentUserValue) {
                  this.router.navigate(['/']);
              }
  }

  ngOnInit() {
    this.year = new Date().getFullYear();
    this.formLogin = this.formBuilder.group({
        username: ['', [ Validators.required, Validators.email]],
        password: ['', Validators.required]
    });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  login(formValue: any) {
    this.authenticationService.login( formValue.username , formValue.password )
      .pipe(first())
      .subscribe( data => {
          this.router.navigate(['/dashboard']);
        },
        error => {
          swal.fire('Login', 'No tienes permiso para entrar a la aplicación', 'error');
        }
      );
  }

}
